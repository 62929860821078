import { ActionType } from "../../actions/ActionType";
import { Action } from "../../actions/event/eventReportActions";

interface EventReportState {
  isLoading: boolean;
  error: string | null;
  eventTypes: {
    activityType: string;
    activityTypeDescription: string;
  }[];
  eventAreas: {
    activityAreaNumber: string;
    activityAreaDescription: string;
  }[];
}

const initialState = {
  isLoading: false,
  error: null,
  eventTypes: [],
  eventAreas: [],
};

const eventReportReducer = (
  state: EventReportState = initialState,
  action: Action
) => {
  switch (action.type) {
    case ActionType.FETCH_EVENT_TYPE_START:
      return {
        isLoading: true,
        error: null,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    case ActionType.FETCH_EVENT_TYPE_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventTypes: action.payload,
        eventAreas: state.eventAreas,
      };
    case ActionType.FETCH_EVENT_TYPE_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    case ActionType.FETCH_EVENT_REPORT_AREAS_START:
      return {
        isLoading: true,
        error: null,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    case ActionType.FETCH_EVENT_REPORT_AREAS_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventTypes: state.eventTypes,
        eventAreas: action.payload,
      };
    case ActionType.FETCH_EVENT_REPORT_AREAS_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    case ActionType.DL_EVENT_REPORT_START:
      return {
        isLoading: true,
        error: null,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    case ActionType.DL_EVENT_REPORT_SUCCESS:
      return {
        isLoading: false,
        error: null,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    case ActionType.DL_EVENT_REPORT_ERROR:
      return {
        isLoading: false,
        error: action.payload,
        eventTypes: state.eventTypes,
        eventAreas: state.eventAreas,
      };
    default:
      return state;
  }
};

export default eventReportReducer;

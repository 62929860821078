export enum ActionType {
  FETCH_VIDEO_MAIN_TYPES_LIST_START = "FETCH_VIDEO_MAIN_TYPES_LIST_START",
  FETCH_VIDEO_MAIN_TYPES_LIST_SUCCESS = "FETCH_VIDEO_MAIN_TYPES_LIST_SUCCESS",
  FETCH_VIDEO_MAIN_TYPES_LIST_ERROR = "FETCH_VIDEO_MAIN_TYPES_LIST_ERROR",
  FETCH_CAP_ITEMS_START = "FETCH_CAP_ITEMS_START",
  FETCH_CAP_ITEMS_SUCCESS = "FETCH_CAP_ITEMS_SUCCESS",
  FETCH_CAP_ITEMS_ERROR = "FETCH_CAP_ITEMS_ERROR",
  UPDATE_CAP_ITEM_VERIFY_MSG_START = "UPDATE_CAP_ITEM_VERIFY_MSG_START",
  UPDATE_CAP_ITEM_VERIFY_MSG_SUCCESS = "UPDATE_CAP_ITEM_VERIFY_MSG_SUCCESS",
  UPDATE_CAP_ITEM_VERIFY_MSG_ERROR = "UPDATE_CAP_ITEM_VERIFY_MSG_ERROR",
  FETCH_EVALUATION_START = "FETCH_EVALUATION_START",
  FETCH_EVALUATION_SUCCESS = "FETCH_EVALUATION_SUCCESS",
  FETCH_EVALUATION_ERROR = "FETCH_EVALUATION_ERROR",
  UPDATE_EVALUATION_START = "UPDATE_EVALUATION_START",
  UPDATE_EVALUATION_SUCCESS = "UPDATE_EVALUATION_SUCCESS",
  UPDATE_EVALUATION_ERROR = "UPDATE_EVALUATION_ERROR",
  FETCH_PERFORMERS_START = "FETCH_PERFORMERS_START",
  FETCH_PERFORMERS_SUCCESS = "FETCH_PERFORMERS_SUCCESS",
  FETCH_PERFORMERS_ERROR = "FETCH_PERFORMERS_ERROR",
  FETCH_FC_VIDEOS_START = "FETCH_FC_VIDEOS_START",
  FETCH_FC_VIDEOS_SUCCESS = "FETCH_FC_VIDEOS_SUCCESS",
  FETCH_FC_VIDEOS_ERROR = "FETCH_FC_VIDEOS_ERROR",
  FETCH_FC_VIDEO_START = "FETCH_FC_VIDEO_START",
  FETCH_FC_VIDEO_SUCCESS = "FETCH_FC_VIDEO_SUCCESS",
  FETCH_FC_VIDEO_ERROR = "FETCH_FC_VIDEO_ERROR",
  CREATE_FC_VIDEO_START = "CREATE_FC_VIDEO_START",
  CREATE_FC_VIDEO_SUCCESS = "CREATE_FC_VIDEO_SUCCESS",
  CREATE_FC_VIDEO_ERROR = "CREATE_FC_VIDEO_ERROR",
  UPDATE_FC_VIDEO_START = "UPDATE_FC_VIDEO_START",
  UPDATE_FC_VIDEO_SUCCESS = "UPDATE_FC_VIDEO_SUCCESS",
  UPDATE_FC_VIDEO_ERROR = "UPDATE_FC_VIDEO_ERROR",
  DELETE_FC_VIDEO_START = "DELETE_FC_VIDEO_START",
  DELETE_FC_VIDEO_SUCCESS = "DELETE_FC_VIDEO_SUCCESS",
  DELETE_FC_VIDEO_ERROR = "DELETE_FC_VIDEO_ERROR",
  FETCH_SONGS_START = "FETCH_SONGS_START",
  FETCH_SONGS_SUCCESS = "FETCH_SONGS_SUCCESS",
  FETCH_SONGS_ERROR = "FETCH_SONGS_ERROR",
  FETCH_PROGRAM_TYPES_START = "FETCH_PROGRAM_TYPES_START",
  FETCH_PROGRAM_TYPES_SUCCESS = "FETCH_PROGRAM_TYPES_SUCCESS",
  FETCH_PROGRAM_TYPES_ERROR = "FETCH_PROGRAM_TYPES_ERROR",
  UPDATE_PROGRAM_TYPE_SORT_START = "UPDATE_PROGRAM_TYPE_SORT_START",
  UPDATE_PROGRAM_TYPE_SORT_SUCCESS = "UPDATE_PROGRAM_TYPE_SORT_SUCCESS",
  UPDATE_PROGRAM_TYPE_SORT_ERROR = "UPDATE_PROGRAM_TYPE_SORT_ERROR",
  CREATE_PROGRAM_TYPE_START = "CREATE_PROGRAM_TYPE_START",
  CREATE_PROGRAM_TYPE_SUCCESS = "CREATE_PROGRAM_TYPE_SUCCESS",
  CREATE_PROGRAM_TYPE_ERROR = "CREATE_PROGRAM_TYPE_ERROR",
  DELETE_PROGRAM_TYPE_START = "DELETE_PROGRAM_TYPE_START",
  DELETE_PROGRAM_TYPE_SUCCESS = "DELETE_PROGRAM_TYPE_SUCCESS",
  DELETE_PROGRAM_TYPE_ERROR = "DELETE_PROGRAM_TYPE_ERROR",
  FETCH_PROGRAM_TYPE_START = "FETCH_PROGRAM_TYPE_START",
  FETCH_PROGRAM_TYPE_SUCCESS = "FETCH_PROGRAM_TYPE_SUCCESS",
  FETCH_PROGRAM_TYPE_ERROR = "FETCH_PROGRAM_TYPE_ERROR",
  UPDATE_PROGRAM_TYPE_START = "UPDATE_PROGRAM_TYPE_START",
  UPDATE_PROGRAM_TYPE_SUCCESS = "UPDATE_PROGRAM_TYPE_SUCCESS",
  UPDATE_PROGRAM_TYPE_ERROR = "UPDATE_PROGRAM_TYPE_ERROR",
  FETCH_PROGRAM_MENU_START = "FETCH_PROGRAM_MENU_START",
  FETCH_PROGRAM_MENU_SUCCESS = "FETCH_PROGRAM_MENU_SUCCESS",
  FETCH_PROGRAM_MENU_ERROR = "FETCH_PROGRAM_MENU_ERROR",
  UPDATE_PROGRAM_MENU_SORT_START = "UPDATE_PROGRAM_MENU_SORT_START",
  UPDATE_PROGRAM_MENU_SORT_SUCCESS = "UPDATE_PROGRAM_MENU_SORT_SUCCESS",
  UPDATE_PROGRAM_MENU_SORT_ERROR = "UPDATE_PROGRAM_MENU_SORT_ERROR",
  DELETE_PROGRAM_MENU_START = "DELETE_PROGRAM_MENU_START",
  DELETE_PROGRAM_MENU_SUCCESS = "DELETE_PROGRAM_MENU_SUCCESS",
  DELETE_PROGRAM_MENU_ERROR = "DELETE_PROGRAM_MENU_ERROR",
  CREATE_PROGRAM_MENU_START = "CREATE_PROGRAM_MENU_START",
  CREATE_PROGRAM_MENU_SUCCESS = "CREATE_PROGRAM_MENU_SUCCESS",
  CREATE_PROGRAM_MENU_ERROR = "CREATE_PROGRAM_MENU_ERROR",
  UPDATE_PROGRAM_MENU_START = "UPDATE_PROGRAM_MENU_START",
  UPDATE_PROGRAM_MENU_SUCCESS = "UPDATE_PROGRAM_MENU_SUCCESS",
  UPDATE_PROGRAM_MENU_ERROR = "UPDATE_PROGRAM_MENU_ERROR",
  FETCH_ONE_PROGRAM_MENU_START = "FETCH_ONE_PROGRAM_MENU_START",
  FETCH_ONE_PROGRAM_MENU_SUCCESS = "FETCH_ONE_PROGRAM_MENU_SUCCESS",
  FETCH_ONE_PROGRAM_MENU_ERROR = "FETCH_ONE_PROGRAM_MENU_ERROR",
  FETCH_PROGRAMS_LIST_START = "FETCH_PROGRAMS_LIST_START",
  FETCH_PROGRAMS_LIST_SUCCESS = "FETCH_PROGRAMS_LIST_SUCCESS",
  FETCH_PROGRAMS_LIST_ERROR = "FETCH_PROGRAMS_LIST_ERROR",
  UPDATE_PROGRAM_SORT_START = "UPDATE_PROGRAM_SORT_START",
  UPDATE_PROGRAM_SORT_SUCCESS = "UPDATE_PROGRAM_SORT_SUCCESS",
  UPDATE_PROGRAM_SORT_ERROR = "UPDATE_PROGRAM_SORT_ERROR",
  DELETE_PROGRAM_START = "DELETE_PROGRAM_START",
  DELETE_PROGRAM_SUCCESS = "DELETE_PROGRAM_SUCCESS",
  DELETE_PROGRAM_ERROR = "DELETE_PROGRAM_ERROR",
  CREATE_PROGRAM_START = "CREATE_PROGRAM_START",
  CREATE_PROGRAM_SUCCESS = "CREATE_PROGRAM_SUCCESS",
  CREATE_PROGRAM_ERROR = "CREATE_PROGRAM_ERROR",
  FETCH_PROGRAM_START = "FETCH_PROGRAM_START",
  FETCH_PROGRAM_SUCCESS = "FETCH_PROGRAM_SUCCESS",
  FETCH_PROGRAM_ERROR = "FETCH_PROGRAM_ERROR",
  UPDATE_PROGRAM_START = "UPDATE_PROGRAM_START",
  UPDATE_PROGRAM_SUCCESS = "UPDATE_PROGRAM_SUCCESS",
  UPDATE_PROGRAM_ERROR = "UPDATE_PROGRAM_ERROR",
  FETCH_GROUPS_NAME_LIST_START = "FETCH_GROUPS_NAME_LIST_START",
  FETCH_GROUPS_NAME_LIST_SUCCESS = "FETCH_GROUPS_NAME_LIST_SUCCESS",
  FETCH_GROUPS_NAME_LIST_ERROR = "FETCH_GROUPS_NAME_LIST_ERROR",
  DELETE_GROUP_START = "DELETE_GROUP_START",
  DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS",
  DELETE_GROUP_ERROR = "DELETE_GROUP_ERROR",
  FETCH_GROUPS_LIST_START = "FETCH_GROUPS_LIST_START",
  FETCH_GROUPS_LIST_SUCCESS = "FETCH_GROUPS_LIST_SUCCESS",
  FETCH_GROUPS_LIST_ERROR = "FETCH_GROUPS_LIST_ERROR",
  CREATE_GROUP_START = "CREATE_GROUP_START",
  CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS",
  CREATE_GROUP_ERROR = "CREATE_GROUP_ERROR",
  FETCH_GROUP_START = "FETCH_GROUP_START",
  FETCH_GROUP_SUCCESS = "FETCH_GROUP_SUCCESS",
  FETCH_GROUP_ERROR = "FETCH_GROUP_ERROR",
  UPDATE_GROUP_START = "UPDATE_GROUP_START",
  UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS",
  UPDATE_GROUP_ERROR = "UPDATE_GROUP_ERROR",
  FETCH_USERS_LIST_START = "FETCH_USERS_LIST_START",
  FETCH_USERS_LIST_SUCCESS = "FETCH_USERS_LIST_SUCCESS",
  FETCH_USERS_LIST_ERROR = "FETCH_USERS_LIST_ERROR",
  DELETE_USER_START = "DELETE_USER_START",
  DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS",
  DELETE_USER_ERROR = "DELETE_USER_ERROR",
  FETCH_EMPLOYEES_LIST_START = "FETCH_EMPLOYEES_LIST_START",
  FETCH_EMPLOYEES_LIST_SUCCESS = "FETCH_EMPLOYEES_LIST_SUCCESS",
  FETCH_EMPLOYEES_LIST_ERROR = "FETCH_EMPLOYEES_LIST_ERROR",
  CREATE_USER_START = "CREATE_USER_START",
  CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS",
  CREATE_USER_ERROR = "CREATE_USER_ERROR",
  UPDATE_USER_START = "UPDATE_USER_START",
  UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS",
  UPDATE_USER_ERROR = "UPDATE_USER_ERROR",
  FETCH_USER_START = "FETCH_USER_START",
  FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS",
  FETCH_USER_ERROR = "FETCH_USER_ERROR",
  FETCH_PERFORMERS_LIST_START = "FETCH_PERFORMERS_LIST_START",
  FETCH_PERFORMERS_LIST_SUCCESS = "FETCH_PERFORMERS_LIST_SUCCESS",
  FETCH_PERFORMERS_LIST_ERROR = "FETCH_PERFORMERS_LIST_ERROR",
  CREATE_PERFORMER_START = "CREATE_PERFORMER_START",
  CREATE_PERFORMER_SUCCESS = "CREATE_PERFORMER_SUCCESS",
  CREATE_PERFORMER_ERROR = "CREATE_PERFORMER_ERROR",
  FETCH_PERFORMER_START = "FETCH_PERFORMER_START",
  FETCH_PERFORMER_SUCCESS = "FETCH_PERFORMER_SUCCESS",
  FETCH_PERFORMER_ERROR = "FETCH_PERFORMER_ERROR",
  UPDATE_PERFORMER_START = "UPDATE_PERFORMER_START",
  UPDATE_PERFORMER_SUCCESS = "UPDATE_PERFORMER_SUCCESS",
  UPDATE_PERFORMER_ERROR = "UPDATE_PERFORMER_ERROR",
  FETCH_CAP_HOMEWORKS_LIST_START = "FETCH_CAP_HOMEWORKS_LIST_START",
  FETCH_CAP_HOMEWORKS_LIST_SUCCESS = "FETCH_CAP_HOMEWORKS_LIST_SUCCESS",
  FETCH_CAP_HOMEWORKS_LIST_ERROR = "FETCH_CAP_HOMEWORKS_LIST_ERROR",
  FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_START = "FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_START",
  FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_SUCCESS = "FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_SUCCESS",
  FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_ERROR = "FETCH_CAP_HOMEWORKS_LIST_BY_MEMBER_ERROR",
  CAP_CHECKING_START = "CAP_CHECKING_START",
  CAP_CHECKING_SUCCESS = "CAP_CHECKING_SUCCESS",
  CAP_CHECKING_ERROR = "CAP_CHECKING_ERROR",
  FETCH_SERVICE_USAGE_START = "FETCH_SERVICE_USAGE_START",
  FETCH_SERVICE_USAGE_SUCCESS = "FETCH_SERVICE_USAGE_SUCCESS",
  FETCH_SERVICE_USAGE_ERROR = "FETCH_SERVICE_USAGE_ERROR",
  FETCH_MENU_LIST_START = "FETCH_MENU_LIST_START",
  FETCH_MENU_LIST_SUCCESS = "FETCH_MENU_LIST_SUCCESS",
  FETCH_MENU_LIST_ERROR = "FETCH_MENU_LIST_ERROR",
  FETCH_LESSONS_LIST_START = "FETCH_LESSONS_LIST_START",
  FETCH_LESSONS_LIST_SUCCESS = "FETCH_LESSONS_LIST_SUCCESS",
  FETCH_LESSONS_LIST_ERROR = "FETCH_LESSONS_LIST_ERROR",
  FETCH_LESSON_START = "FETCH_LESSON_START",
  FETCH_LESSON_SUCCESS = "FETCH_LESSON_SUCCESS",
  FETCH_LESSON_ERROR = "FETCH_LESSON_ERROR",
  UPDATE_LESSON_START = "UPDATE_LESSON_START",
  UPDATE_LESSON_SUCCESS = "UPDATE_LESSON_SUCCESS",
  UPDATE_LESSON_ERROR = "UPDATE_LESSON_ERROR",
  CREATE_VIDEO_MAIN_TYPE_START = "CREATE_VIDEO_MAIN_TYPE_START",
  CREATE_VIDEO_MAIN_TYPE_SUCCESS = "CREATE_VIDEO_MAIN_TYPE_SUCCESS",
  CREATE_VIDEO_MAIN_TYPE_ERROR = "CREATE_VIDEO_MAIN_TYPE_ERROR",
  UPDATE_VIDEO_MAIN_TYPE_START = "UPDATE_VIDEO_MAIN_TYPE_START",
  UPDATE_VIDEO_MAIN_TYPE_SUCCESS = "UPDATE_VIDEO_MAIN_TYPE_SUCCESS",
  UPDATE_VIDEO_MAIN_TYPE_ERROR = "UPDATE_VIDEO_MAIN_TYPE_ERROR",
  FETCH_VIDEO_MAIN_TYPE_START = "FETCH_VIDEO_MAIN_TYPE_START",
  FETCH_VIDEO_MAIN_TYPE_SUCCESS = "FETCH_VIDEO_MAIN_TYPE_SUCCESS",
  FETCH_VIDEO_MAIN_TYPE_ERROR = "FETCH_VIDEO_MAIN_TYPE_ERROR",
  DELETE_VIDEO_MAIN_TYPE_START = "DELETE_VIDEO_MAIN_TYPE_START",
  DELETE_VIDEO_MAIN_TYPE_SUCCESS = "DELETE_VIDEO_MAIN_TYPE_SUCCESS",
  DELETE_VIDEO_MAIN_TYPE_ERROR = "DELETE_VIDEO_MAIN_TYPE_ERROR",
  FETCH_VIDEO_SUB_TYPES_LIST_START = "FETCH_VIDEO_SUB_TYPES_LIST_START",
  FETCH_VIDEO_SUB_TYPES_LIST_SUCCESS = "FETCH_VIDEO_SUB_TYPES_LIST_SUCCESS",
  FETCH_VIDEO_SUB_TYPES_LIST_ERROR = "FETCH_VIDEO_SUB_TYPES_LIST_ERROR",
  CREATE_VIDEO_SUB_TYPE_START = "CREATE_VIDEO_SUB_TYPE_START",
  CREATE_VIDEO_SUB_TYPE_SUCCESS = "CREATE_VIDEO_SUB_TYPE_SUCCESS",
  CREATE_VIDEO_SUB_TYPE_ERROR = "CREATE_VIDEO_SUB_TYPE_ERROR",
  UPDATE_VIDEO_SUB_TYPE_START = "UPDATE_VIDEO_SUB_TYPE_START",
  UPDATE_VIDEO_SUB_TYPE_SUCCESS = "UPDATE_VIDEO_SUB_TYPE_SUCCESS",
  UPDATE_VIDEO_SUB_TYPE_ERROR = "UPDATE_VIDEO_SUB_TYPE_ERROR",
  FETCH_VIDEO_SUB_TYPE_START = "FETCH_VIDEO_SUB_TYPE_START",
  FETCH_VIDEO_SUB_TYPE_SUCCESS = "FETCH_VIDEO_SUB_TYPE_SUCCESS",
  FETCH_VIDEO_SUB_TYPE_ERROR = "FETCH_VIDEO_SUB_TYPE_ERROR",
  DELETE_VIDEO_SUB_TYPE_START = "DELETE_VIDEO_SUB_TYPE_START",
  DELETE_VIDEO_SUB_TYPE_SUCCESS = "DELETE_VIDEO_SUB_TYPE_SUCCESS",
  DELETE_VIDEO_SUB_TYPE_ERROR = "DELETE_VIDEO_SUB_TYPE_ERROR",
  FETCH_VIDEOS_LIST_START = "FETCH_VIDEOS_LIST_START",
  FETCH_VIDEOS_LIST_SUCCESS = "FETCH_VIDEOS_LIST_SUCCESS",
  FETCH_VIDEOS_LIST_ERROR = "FETCH_VIDEOS_LIST_ERROR",
  CREATE_VIDEO_START = "CREATE_VIDEO_START",
  CREATE_VIDEO_SUCCESS = "CREATE_VIDEO_SUCCESS",
  CREATE_VIDEO_ERROR = "CREATE_VIDEO_ERROR",
  FETCH_VIDEO_START = "FETCH_VIDEO_START",
  FETCH_VIDEO_SUCCESS = "FETCH_VIDEO_SUCCESS",
  FETCH_VIDEO_ERROR = "FETCH_VIDEO_ERROR",
  UPDATE_VIDEO_START = "UPDATE_VIDEO_START",
  UPDATE_VIDEO_SUCCESS = "UPDATE_VIDEO_SUCCESS",
  UPDATE_VIDEO_ERROR = "UPDATE_VIDEO_ERROR",
  DELETE_VIDEO_START = "DELETE_VIDEO_START",
  DELETE_VIDEO_SUCCESS = "DELETE_VIDEO_SUCCESS",
  DELETE_VIDEO_ERROR = "DELETE_VIDEO_ERROR",
  FETCH_GIFTS_LIST_START = "FETCH_GIFTS_LIST_START",
  FETCH_GIFTS_LIST_SUCCESS = "FETCH_GIFTS_LIST_SUCCESS",
  FETCH_GIFTS_LIST_ERROR = "FETCH_GIFTS_LIST_ERROR",
  CREATE_GIFT_START = "CREATE_GIFT_START",
  CREATE_GIFT_SUCCESS = "CREATE_GIFT_SUCCESS",
  CREATE_GIFT_ERROR = "CREATE_GIFT_ERROR",
  UPDATE_GIFT_START = "UPDATE_GIFT_START",
  UPDATE_GIFT_SUCCESS = "UPDATE_GIFT_SUCCESS",
  UPDATE_GIFT_ERROR = "UPDATE_GIFT_ERROR",
  FETCH_GIFT_START = "FETCH_GIFT_START",
  FETCH_GIFT_SUCCESS = "FETCH_GIFT_SUCCESS",
  FETCH_GIFT_ERROR = "FETCH_GIFT_ERROR",
  DELETE_GIFT_START = "DELETE_GIFT_START",
  DELETE_GIFT_SUCCESS = "DELETE_GIFT_SUCCESS",
  DELETE_GIFT_ERROR = "DELETE_GIFT_ERROR",
  FETCH_HEALTH_DECLARATION_LIST_START = "FETCH_HEALTH_DECLARATION_LIST_START",
  FETCH_HEALTH_DECLARATION_LIST_SUCCESS = "FETCH_HEALTH_DECLARATION_LIST_SUCCESS",
  FETCH_HEALTH_DECLARATION_LIST_ERROR = "FETCH_HEALTH_DECLARATION_LIST_ERROR",
  DL_HEALTH_DECLARATION_LIST_START = "DL_HEALTH_DECLARATION_LIST_START",
  DL_HEALTH_DECLARATION_LIST_SUCCESS = "DL_HEALTH_DECLARATION_LIST_SUCCESS",
  DL_HEALTH_DECLARATION_LIST_ERROR = "DL_HEALTH_DECLARATION_LIST_ERROR",
  FETCH_CHILD_LIST_START = "FETCH_CHILD_LIST_START",
  FETCH_CHILD_LIST_SUCCESS = "FETCH_CHILD_LIST_SUCCESS",
  FETCH_CHILD_LIST_ERROR = "FETCH_CHILD_LIST_ERROR",
  FETCH_CAP_ITEMS_LIST_START = "FETCH_CAP_ITEMS_LIST_START",
  FETCH_CAP_ITEMS_LIST_SUCCESS = "FETCH_CAP_ITEMS_LIST_SUCCESS",
  FETCH_CAP_ITEMS_LIST_ERROR = "FETCH_CAP_ITEMS_LIST_ERROR",
  FETCH_SUB_ITEMS_LIST_START = "FETCH_SUB_ITEMS_LIST_START",
  FETCH_SUB_ITEMS_LIST_SUCCESS = "FETCH_SUB_ITEMS_LIST_SUCCESS",
  FETCH_SUB_ITEMS_LIST_ERROR = "FETCH_SUB_ITEMS_LIST_ERROR",
  CAP_APPLY_START = "CAP_APPLY_START",
  CAP_APPLY_SUCCESS = "CAP_APPLY_SUCCESS",
  CAP_APPLY_ERROR = "CAP_APPLY_ERROR",
  FETCH_GIFT_LABELS_LIST_START = "FETCH_GIFT_LABELS_LIST_START",
  FETCH_GIFT_LABELS_LIST_SUCCESS = "FETCH_GIFT_LABELS_LIST_SUCCESS",
  FETCH_GIFT_LABELS_LIST_ERROR = "FETCH_GIFT_LABELS_LIST_ERROR",
  FETCH_GIFT_LABELS_LIST_BY_RANGE_START = "FETCH_GIFT_LABELS_LIST_BY_RANGE_START",
  FETCH_GIFT_LABELS_LIST_BY_RANGE_SUCCESS = "FETCH_GIFT_LABELS_LIST_BY_RANGE_SUCCESS",
  FETCH_GIFT_LABELS_LIST_BY_RANGE_ERROR = "FETCH_GIFT_LABELS_LIST_BY_RANGE_ERROR",
  UPDATE_GIFT_LABELS_STATUS_START = "UPDATE_GIFT_LABELS_STATUS_START",
  UPDATE_GIFT_LABELS_STATUS_SUCCESS = "UPDATE_GIFT_LABELS_STATUS_SUCCESS",
  UPDATE_GIFT_LABELS_STATUS_ERROR = "UPDATE_GIFT_LABELS_STATUS_ERROR",
  FETCH_YEARS_START = "FETCH_YEARS_START",
  FETCH_YEARS_SUCCESS = "FETCH_YEARS_SUCCESS",
  FETCH_YEARS_ERROR = "FETCH_YEARS_ERROR",
  FETCH_RECOMMENDS_START = "FETCH_RECOMMENDS_START",
  FETCH_RECOMMENDS_SUCCESS = "FETCH_RECOMMENDS_SUCCESS",
  FETCH_RECOMMENDS_ERROR = "FETCH_RECOMMENDS_ERROR",
  FETCH_RECOMMENDS_BY_MONTH_START = "FETCH_RECOMMENDS_BY_MONTH_START",
  FETCH_RECOMMENDS_BY_MONTH_SUCCESS = "FETCH_RECOMMENDS_BY_MONTH_SUCCESS",
  FETCH_RECOMMENDS_BY_MONTH_ERROR = "FETCH_RECOMMENDS_BY_MONTH_ERROR",
  CREATE_YEAR_START = "CREATE_YEAR_START",
  CREATE_YEAR_SUCCESS = "CREATE_YEAR_SUCCESS",
  CREATE_YEAR_ERROR = "CREATE_YEAR_ERROR",
  UPDATE_RECOMMEND_START = "UPDATE_RECOMMEND_START",
  UPDATE_RECOMMEND_SUCCESS = "UPDATE_RECOMMEND_SUCCESS",
  UPDATE_RECOMMEND_ERROR = "UPDATE_RECOMMEND_ERROR",
  FETCH_RECOMMEND_PERFORMERS_START = "FETCH_RECOMMEND_PERFORMERS_START",
  FETCH_RECOMMEND_PERFORMERS_SUCCESS = "FETCH_RECOMMEND_PERFORMERS_SUCCESS",
  FETCH_RECOMMEND_PERFORMERS_ERROR = "FETCH_RECOMMEND_PERFORMERS_ERROR",
  FETCH_CREW_ZOOM_INFO_LIST_START = "FETCH_CREW_ZOOM_INFO_LIST_START",
  FETCH_CREW_ZOOM_INFO_LIST_SUCCESS = "FETCH_CREW_ZOOM_INFO_LIST_SUCCESS",
  FETCH_CREW_ZOOM_INFO_LIST_ERROR = "FETCH_CREW_ZOOM_INFO_LIST_ERROR",
  UPDATE_CREW_ZOOM_INFO_START = "UPDATE_CREW_ZOOM_INFO_START",
  UPDATE_CREW_ZOOM_INFO_SUCCESS = "UPDATE_CREW_ZOOM_INFO_SUCCESS",
  UPDATE_CREW_ZOOM_INFO_ERROR = "UPDATE_CREW_ZOOM_INFO_ERROR",
  FETCH_FREE_TALK_TOPICS_LIST_START = "FETCH_FREE_TALK_TOPICS_LIST_START",
  FETCH_FREE_TALK_TOPICS_LIST_SUCCESS = "FETCH_FREE_TALK_TOPICS_LIST_SUCCESS",
  FETCH_FREE_TALK_TOPICS_LIST_ERROR = "FETCH_FREE_TALK_TOPICS_LIST_ERROR",
  CREATE_FREE_TALK_TOPIC_START = "CREATE_FREE_TALK_TOPIC_START",
  CREATE_FREE_TALK_TOPIC_SUCCESS = "CREATE_FREE_TALK_TOPIC_SUCCESS",
  CREATE_FREE_TALK_TOPIC_ERROR = "CREATE_FREE_TALK_TOPIC_ERROR",
  FETCH_FREE_TALK_TOPIC_START = "FETCH_FREE_TALK_TOPIC_START",
  FETCH_FREE_TALK_TOPIC_SUCCESS = "FETCH_FREE_TALK_TOPIC_SUCCESS",
  FETCH_FREE_TALK_TOPIC_ERROR = "FETCH_FREE_TALK_TOPIC_ERROR",
  UPDATE_FREE_TALK_TOPIC_START = "UPDATE_FREE_TALK_TOPIC_START",
  UPDATE_FREE_TALK_TOPIC_SUCCESS = "UPDATE_FREE_TALK_TOPIC_SUCCESS",
  UPDATE_FREE_TALK_TOPIC_ERROR = "UPDATE_FREE_TALK_TOPIC_ERROR",
  FETCH_FREE_TALK_YEARS_START = "FETCH_FREE_TALK_YEARS_START",
  FETCH_FREE_TALK_YEARS_SUCCESS = "FETCH_FREE_TALK_YEARS_SUCCESS",
  FETCH_FREE_TALK_YEARS_ERROR = "FETCH_FREE_TALK_YEARS_ERROR",
  FETCH_TOPICS_START = "FETCH_TOPICS_START",
  FETCH_TOPICS_SUCCESS = "FETCH_TOPICS_SUCCESS",
  FETCH_TOPICS_ERROR = "FETCH_TOPICS_ERROR",
  FETCH_TOPICS_BY_MONTH_START = "FETCH_TOPICS_BY_MONTH_START",
  FETCH_TOPICS_BY_MONTH_SUCCESS = "FETCH_TOPICS_BY_MONTH_SUCCESS",
  FETCH_TOPICS_BY_MONTH_ERROR = "FETCH_TOPICS_BY_MONTH_ERROR",
  UPDATE_MONTHLY_TOPICS_START = "UPDATE_MONTHLY_TOPICS_START",
  UPDATE_MONTHLY_TOPICS_SUCCESS = "UPDATE_MONTHLY_TOPICS_SUCCESS",
  UPDATE_MONTHLY_TOPICS_ERROR = "UPDATE_MONTHLY_TOPICS_ERROR",
  CREATE_NEW_TOPICS_BY_YEAR_START = "CREATE_NEW_TOPICS_BY_YEAR_START",
  CREATE_NEW_TOPICS_BY_YEAR_SUCCESS = "CREATE_NEW_TOPICS_BY_YEAR_SUCCESS",
  CREATE_NEW_TOPICS_BY_YEAR_ERROR = "CREATE_NEW_TOPICS_BY_YEAR_ERROR",
  FETCH_APP_VERSION_START = "FETCH_APP_VERSION_START",
  FETCH_APP_VERSION_SUCCESS = "FETCH_APP_VERSION_SUCCESS",
  FETCH_APP_VERSION_ERROR = "FETCH_APP_VERSION_ERROR",
  UPDATE_APP_VERSION_START = "UPDATE_APP_VERSION_START",
  UPDATE_APP_VERSION_SUCCESS = "UPDATE_APP_VERSION_SUCCESS",
  UPDATE_APP_VERSION_ERROR = "UPDATE_APP_VERSION_ERROR",
  FETCH_APP_BANNERS_START = "FETCH_APP_BANNERS_START",
  FETCH_APP_BANNERS_SUCCESS = "FETCH_APP_BANNERS_SUCCESS",
  FETCH_APP_BANNERS_ERROR = "FETCH_APP_BANNERS_ERROR",
  FETCH_APP_BANNER_START = "FETCH_APP_BANNER_START",
  FETCH_APP_BANNER_SUCCESS = "FETCH_APP_BANNER_SUCCESS",
  FETCH_APP_BANNER_ERROR = "FETCH_APP_BANNER_ERROR",
  RESORT_APP_BANNERS_START = "RESORT_APP_BANNERS_START",
  RESORT_APP_BANNERS_SUCCESS = "RESORT_APP_BANNERS_SUCCESS",
  RESORT_APP_BANNERS_ERROR = "RESORT_APP_BANNERS_ERROR",
  CREATE_APP_BANNER_START = "CREATE_APP_BANNER_START",
  CREATE_APP_BANNER_SUCCESS = "CREATE_APP_BANNER_SUCCESS",
  CREATE_APP_BANNER_ERROR = "CREATE_APP_BANNER_ERROR",
  UPDATE_APP_BANNER_START = "UPDATE_APP_BANNER_START",
  UPDATE_APP_BANNER_SUCCESS = "UPDATE_APP_BANNER_SUCCESS",
  UPDATE_APP_BANNER_ERROR = "UPDATE_APP_BANNER_ERROR",
  DELETE_APP_BANNER_START = "DELETE_APP_BANNER_START",
  DELETE_APP_BANNER_SUCCESS = "DELETE_APP_BANNER_SUCCESS",
  DELETE_APP_BANNER_ERROR = "DELETE_APP_BANNER_ERROR",
  FETCH_TE_CREW_SEATS_LIST_START = "FETCH_TE_CREW_SEATS_LIST_START",
  FETCH_TE_CREW_SEATS_LIST_SUCCESS = "FETCH_TE_CREW_SEATS_LIST_SUCCESS",
  FETCH_TE_CREW_SEATS_LIST_ERROR = "FETCH_TE_CREW_SEATS_LIST_ERROR",
  DELETE_TE_CREW_SEAT_START = "DELETE_TE_CREW_SEAT_START",
  DELETE_TE_CREW_SEAT_SUCCESS = "DELETE_TE_CREW_SEAT_SUCCESS",
  DELETE_TE_CREW_SEAT_ERROR = "DELETE_TE_CREW_SEAT_ERROR",
  CREATE_TE_CREW_SEAT_START = "CREATE_TE_CREW_SEAT_START",
  CREATE_TE_CREW_SEAT_SUCCESS = "CREATE_TE_CREW_SEAT_SUCCESS",
  CREATE_TE_CREW_SEAT_ERROR = "CREATE_TE_CREW_SEAT_ERROR",
  UPDATE_TE_CREW_SEAT_START = "UPDATE_TE_CREW_SEAT_START",
  UPDATE_TE_CREW_SEAT_SUCCESS = "UPDATE_TE_CREW_SEAT_SUCCESS",
  UPDATE_TE_CREW_SEAT_ERROR = "UPDATE_TE_CREW_SEAT_ERROR",
  FETCH_TE_CREW_SEAT_START = "FETCH_TE_CREW_SEAT_START",
  FETCH_TE_CREW_SEAT_SUCCESS = "FETCH_TE_CREW_SEAT_SUCCESS",
  FETCH_TE_CREW_SEAT_ERROR = "FETCH_TE_CREW_SEAT_ERROR",
  FETCH_CAP_LABELS_LIST_START = "FETCH_CAP_LABELS_LIST_START",
  FETCH_CAP_LABELS_LIST_SUCCESS = "FETCH_CAP_LABELS_LIST_SUCCESS",
  FETCH_CAP_LABELS_LIST_ERROR = "FETCH_CAP_LABELS_LIST_ERROR",
  UPDATE_CAP_LABELS_STATUS_START = "UPDATE_CAP_LABELS_STATUS_START",
  UPDATE_CAP_LABELS_STATUS_SUCCESS = "UPDATE_CAP_LABELS_STATUS_SUCCESS",
  UPDATE_CAP_LABELS_STATUS_ERROR = "UPDATE_CAP_LABELS_STATUS_ERROR",
  FETCH_CAP_LABELS_LIST_BY_RANGE_START = "FETCH_CAP_LABELS_LIST_BY_RANGE_START",
  FETCH_CAP_LABELS_LIST_BY_RANGE_SUCCESS = "FETCH_CAP_LABELS_LIST_BY_RANGE_SUCCESS",
  FETCH_CAP_LABELS_LIST_BY_RANGE_ERROR = "FETCH_CAP_LABELS_LIST_BY_RANGE_ERROR",
  FETCH_CHILDREN_LIST_START = "FETCH_CHILDREN_LIST_START",
  FETCH_CHILDREN_LIST_SUCCESS = "FETCH_CHILDREN_LIST_SUCCESS",
  FETCH_CHILDREN_LIST_ERROR = "FETCH_CHILDREN_LIST_ERROR",
  FETCH_HOMEWORKS_LIST_BY_CHILD_START = "FETCH_HOMEWORKS_LIST_BY_CHILD_START",
  FETCH_HOMEWORKS_LIST_BY_CHILD_SUCCESS = "FETCH_HOMEWORKS_LIST_BY_CHILD_SUCCESS",
  FETCH_HOMEWORKS_LIST_BY_CHILD_ERROR = "FETCH_HOMEWORKS_LIST_BY_CHILD_ERROR",
  DELETE_HOMEWORK_BY_CHILD_START = "DELETE_HOMEWORK_BY_CHILD_START",
  DELETE_HOMEWORK_BY_CHILD_SUCCESS = "DELETE_HOMEWORK_BY_CHILD_SUCCESS",
  DELETE_HOMEWORK_BY_CHILD_ERROR = "DELETE_HOMEWORK_BY_CHILD_ERROR",
  UPDATE_HOMEWORK_BY_CHILD_START = "UPDATE_HOMEWORK_BY_CHILD_START",
  UPDATE_HOMEWORK_BY_CHILD_SUCCESS = "UPDATE_HOMEWORK_BY_CHILD_SUCCESS",
  UPDATE_HOMEWORK_BY_CHILD_ERROR = "UPDATE_HOMEWORK_BY_CHILD_ERROR",
  DL_CAP_NAME_LIST_START = "DL_CAP_NAME_LIST_START",
  DL_CAP_NAME_LIST_SUCCESS = "DL_CAP_NAME_LIST_SUCCESS",
  DL_CAP_NAME_LIST_ERROR = "DL_CAP_NAME_LIST_ERROR",
  DL_CAP_CERTIFICATE_LIST_START = "DL_CAP_CERTIFICATE_LIST_START",
  DL_CAP_CERTIFICATE_LIST_SUCCESS = "DL_CAP_CERTIFICATE_LIST_SUCCESS",
  DL_CAP_CERTIFICATE_LIST_ERROR = "DL_CAP_CERTIFICATE_LIST_ERROR",
  FETCH_LAST_TE_DAILY_LOG_START = "FETCH_LAST_TE_DAILY_LOG_START",
  FETCH_LAST_TE_DAILY_LOG_SUCCESS = "FETCH_LAST_TE_DAILY_LOG_SUCCESS",
  FETCH_LAST_TE_DAILY_LOG_ERROR = "FETCH_LAST_TE_DAILY_LOG_ERROR",
  FETCH_SPECIFIC_TE_DAILY_LOG_START = "FETCH_SPECIFIC_TE_DAILY_LOG_START",
  FETCH_SPECIFIC_TE_DAILY_LOG_SUCCESS = "FETCH_SPECIFIC_TE_DAILY_LOG_SUCCESS",
  FETCH_SPECIFIC_TE_DAILY_LOG_ERROR = "FETCH_SPECIFIC_TE_DAILY_LOG_ERROR",
  FETCH_RANGE_TE_DAILY_LOG_START = "FETCH_RANGE_TE_DAILY_LOG_START",
  FETCH_RANGE_TE_DAILY_LOG_SUCCESS = "FETCH_RANGE_TE_DAILY_LOG_SUCCESS",
  FETCH_RANGE_TE_DAILY_LOG_ERROR = "FETCH_RANGE_TE_DAILY_LOG_ERROR",
  FETCH_TE_CREW_LIST_START = "FETCH_TE_CREW_LIST_START",
  FETCH_TE_CREW_LIST_SUCCESS = "FETCH_TE_CREW_LIST_SUCCESS",
  FETCH_TE_CREW_LIST_ERROR = "FETCH_TE_CREW_LIST_ERROR",
  FETCH_TE_CREW_LEVEL_LIST_START = "FETCH_TE_CREW_LEVEL_LIST_START",
  FETCH_TE_CREW_LEVEL_LIST_SUCCESS = "FETCH_TE_CREW_LEVEL_LIST_SUCCESS",
  FETCH_TE_CREW_LEVEL_LIST_ERROR = "FETCH_TE_CREW_LEVEL_LIST_ERROR",
  FETCH_TE_CREW_LEVEL_DETAIL_START = "FETCH_TE_CREW_LEVEL_DETAIL_START",
  FETCH_TE_CREW_LEVEL_DETAIL_SUCCESS = "FETCH_TE_CREW_LEVEL_DETAIL_SUCCESS",
  FETCH_TE_CREW_LEVEL_DETAIL_ERROR = "FETCH_TE_CREW_LEVEL_DETAIL_ERROR",
  LESSON_PASS_START = "LESSON_PASS_START",
  LESSON_PASS_SUCCESS = "LESSON_PASS_SUCCESS",
  LESSON_PASS_ERROR = "LESSON_PASS_ERROR",
  LESSON_NOT_PASS_START = "LESSON_NOT_PASS_START",
  LESSON_NOT_PASS_SUCCESS = "LESSON_NOT_PASS_SUCCESS",
  LESSON_NOT_PASS_ERROR = "LESSON_NOT_PASS_ERROR",
  LESSONS_ALL_PASS_START = "LESSONS_ALL_PASS_START",
  LESSONS_ALL_PASS_SUCCESS = "LESSONS_ALL_PASS_SUCCESS",
  LESSONS_ALL_PASS_ERROR = "LESSONS_ALL_PASS_ERROR",
  LESSONS_ALL_NOT_PASS_START = "LESSONS_ALL_NOT_PASS_START",
  LESSONS_ALL_NOT_PASS_SUCCESS = "LESSONS_ALL_NOT_PASS_SUCCESS",
  LESSONS_ALL_NOT_PASS_ERROR = "LESSONS_ALL_NOT_PASS_ERROR",
  FETCH_SMS_FIXED_NAME_LIST_START = "FETCH_SMS_FIXED_NAME_LIST_START",
  FETCH_SMS_FIXED_NAME_LIST_SUCCESS = "FETCH_SMS_FIXED_NAME_LIST_SUCCESS",
  FETCH_SMS_FIXED_NAME_LIST_ERROR = "FETCH_SMS_FIXED_NAME_LIST_ERROR",
  FETCH_SMS_OTHER_LIST_START = "FETCH_SMS_OTHER_LIST_START",
  FETCH_SMS_OTHER_LIST_SUCCESS = "FETCH_SMS_OTHER_LIST_SUCCESS",
  FETCH_SMS_OTHER_LIST_ERROR = "FETCH_SMS_OTHER_LIST_ERROR",
  FETCH_SMS_OPTIONAL_NAME_LIST_START = "FETCH_SMS_OPTIONAL_NAME_LIST_START",
  FETCH_SMS_OPTIONAL_NAME_LIST_SUCCESS = "FETCH_SMS_OPTIONAL_NAME_LIST_SUCCESS",
  FETCH_SMS_OPTIONAL_NAME_LIST_ERROR = "FETCH_SMS_OPTIONAL_NAME_LIST_ERROR",
  CREATE_SMS_FIXED_NAMES_START = "CREATE_SMS_FIXED_NAMES_START",
  CREATE_SMS_FIXED_NAMES_SUCCESS = "CREATE_SMS_FIXED_NAMES_SUCCESS",
  CREATE_SMS_FIXED_NAMES_ERROR = "CREATE_SMS_FIXED_NAMES_ERROR",
  CREATE_SMS_OPTIONAL_NAMES_START = "CREATE_SMS_OPTIONAL_NAMES_START",
  CREATE_SMS_OPTIONAL_NAMES_SUCCESS = "CREATE_SMS_OPTIONAL_NAMES_SUCCESS",
  CREATE_SMS_OPTIONAL_NAMES_ERROR = "CREATE_SMS_OPTIONAL_NAMES_ERROR",
  DELETE_SMS_FIXED_NAME_START = "DELETE_SMS_FIXED_NAME_START",
  DELETE_SMS_FIXED_NAME_SUCCESS = "DELETE_SMS_FIXED_NAME_SUCCESS",
  DELETE_SMS_FIXED_NAME_ERROR = "DELETE_SMS_FIXED_NAME_ERROR",
  DELETE_SMS_OPTIONAL_NAME_START = "DELETE_SMS_OPTIONAL_NAME_START",
  DELETE_SMS_OPTIONAL_NAME_SUCCESS = "DELETE_SMS_OPTIONAL_NAME_SUCCESS",
  DELETE_SMS_OPTIONAL_NAME_ERROR = "DELETE_SMS_OPTIONAL_NAME_ERROR",
  FETCH_SMS_EVENT_LIST_START = "FETCH_SMS_EVENT_LIST_START",
  FETCH_SMS_EVENT_LIST_SUCCESS = "FETCH_SMS_EVENT_LIST_SUCCESS",
  FETCH_SMS_EVENT_LIST_ERROR = "FETCH_SMS_EVENT_LIST_ERROR",
  FETCH_EVENT_AREA_LIST_START = "FETCH_EVENT_AREA_LIST_START",
  FETCH_EVENT_AREA_LIST_SUCCESS = "FETCH_EVENT_AREA_LIST_SUCCESS",
  FETCH_EVENT_AREA_LIST_ERROR = "FETCH_EVENT_AREA_LIST_ERROR",
  FETCH_SMS_NO_APP_OR_PHONE_LIST_START = "FETCH_SMS_NO_APP_OR_PHONE_LIST_START",
  FETCH_SMS_NO_APP_OR_PHONE_LIST_SUCCESS = "FETCH_SMS_NO_APP_OR_PHONE_LIST_SUCCESS",
  FETCH_SMS_NO_APP_OR_PHONE_LIST_ERROR = "FETCH_SMS_NO_APP_OR_PHONE_LIST_ERROR",
  SEND_EVENT_SMS_START = "SEND_EVENT_SMS_START",
  SEND_EVENT_SMS_SUCCESS = "SEND_EVENT_SMS_SUCCESS",
  SEND_EVENT_SMS_ERROR = "SEND_EVENT_SMS_ERROR",
  FETCH_PROMO_COUNT_START = "FETCH_PROMO_COUNT_START",
  FETCH_PROMO_COUNT_SUCCESS = "FETCH_PROMO_COUNT_SUCCESS",
  FETCH_PROMO_COUNT_ERROR = "FETCH_PROMO_COUNT_ERROR",
  FETCH_PROMO_NO_APP_OR_PHONE_LIST_START = "FETCH_PROMO_NO_APP_OR_PHONE_LIST_START",
  FETCH_PROMO_NO_APP_OR_PHONE_LIST_SUCCESS = "FETCH_PROMO_NO_APP_OR_PHONE_LIST_SUCCESS",
  FETCH_PROMO_NO_APP_OR_PHONE_LIST_ERROR = "FETCH_PROMO_NO_APP_OR_PHONE_LIST_ERROR",
  SEND_PROMO_SMS_START = "SEND_PROMO_SMS_START",
  SEND_PROMO_SMS_SUCCESS = "SEND_PROMO_SMS_SUCCESS",
  SEND_PROMO_SMS_ERROR = "SEND_PROMO_SMS_ERROR",
  FETCH_TE_SUPERVISOR_LIST_START = "FETCH_TE_SUPERVISOR_LIST_START",
  FETCH_TE_SUPERVISOR_LIST_SUCCESS = "FETCH_TE_SUPERVISOR_LIST_SUCCESS",
  FETCH_TE_SUPERVISOR_LIST_ERROR = "FETCH_TE_SUPERVISOR_LIST_ERROR",
  FETCH_TE_EMP_LIST_START = "FETCH_TE_EMP_LIST_START",
  FETCH_TE_EMP_LIST_SUCCESS = "FETCH_TE_EMP_LIST_SUCCESS",
  FETCH_TE_EMP_LIST_ERROR = "FETCH_TE_EMP_LIST_ERROR",
  ADD_TE_SUPERVISOR_START = "ADD_TE_SUPERVISOR_START",
  ADD_TE_SUPERVISOR_SUCCESS = "ADD_TE_SUPERVISOR_SUCCESS",
  ADD_TE_SUPERVISOR_ERROR = "ADD_TE_SUPERVISOR_ERROR",
  DELETE_TE_SUPERVISOR_START = "DELETE_TE_SUPERVISOR_START",
  DELETE_TE_SUPERVISOR_SUCCESS = "DELETE_TE_SUPERVISOR_SUCCESS",
  DELETE_TE_SUPERVISOR_ERROR = "DELETE_TE_SUPERVISOR_ERROR",
  FETCH_TAX_INFO_LIST_START = "FETCH_TAX_INFO_LIST_START",
  FETCH_TAX_INFO_LIST_SUCCESS = "FETCH_TAX_INFO_LIST_SUCCESS",
  FETCH_TAX_INFO_LIST_ERROR = "FETCH_TAX_INFO_LIST_ERROR",
  UPDATE_TAX_INFO_START = "UPDATE_TAX_INFO_START",
  UPDATE_TAX_INFO_SUCCESS = "UPDATE_TAX_INFO_SUCCESS",
  UPDATE_TAX_INFO_ERROR = "UPDATE_TAX_INFO_ERROR",
  FETCH_EVENT_EXTRA_FEES_LIST_START = "FETCH_EVENT_EXTRA_FEES_LIST_START",
  FETCH_EVENT_EXTRA_FEES_LIST_SUCCESS = "FETCH_EVENT_EXTRA_FEES_LIST_SUCCESS",
  FETCH_EVENT_EXTRA_FEES_LIST_ERROR = "FETCH_EVENT_EXTRA_FEES_LIST_ERROR",
  ADD_EVENT_EXTRA_FEE_START = "ADD_EVENT_EXTRA_FEE_START",
  ADD_EVENT_EXTRA_FEE_SUCCESS = "ADD_EVENT_EXTRA_FEE_SUCCESS",
  ADD_EVENT_EXTRA_FEE_ERROR = "ADD_EVENT_EXTRA_FEE_ERROR",
  FETCH_EVENT_WORDS_LIST_START = "FETCH_EVENT_WORDS_LIST_START",
  FETCH_EVENT_WORDS_LIST_SUCCESS = "FETCH_EVENT_WORDS_LIST_SUCCESS",
  FETCH_EVENT_WORDS_LIST_ERROR = "FETCH_EVENT_WORDS_LIST_ERROR",
  UPDATE_EVENT_WORD_START = "UPDATE_EVENT_WORD_START",
  UPDATE_EVENT_WORD_SUCCESS = "UPDATE_EVENT_WORD_SUCCESS",
  UPDATE_EVENT_WORD_ERROR = "UPDATE_EVENT_WORD_ERROR",
  FETCH_EVENT_BANNER_TYPES_LIST_START = "FETCH_EVENT_BANNER_TYPES_LIST_START",
  FETCH_EVENT_BANNER_TYPES_LIST_SUCCESS = "FETCH_EVENT_BANNER_TYPES_LIST_SUCCESS",
  FETCH_EVENT_BANNER_TYPES_LIST_ERROR = "FETCH_EVENT_BANNER_TYPES_LIST_ERROR",
  FETCH_EVENT_BANNERS_LIST_START = "FETCH_EVENT_BANNERS_LIST_START",
  FETCH_EVENT_BANNERS_LIST_SUCCESS = "FETCH_EVENT_BANNERS_LIST_SUCCESS",
  FETCH_EVENT_BANNERS_LIST_ERROR = "FETCH_EVENT_BANNERS_LIST_ERROR",
  FETCH_EVENT_BANNER_START = "FETCH_EVENT_BANNER_START",
  FETCH_EVENT_BANNER_SUCCESS = "FETCH_EVENT_BANNER_SUCCESS",
  FETCH_EVENT_BANNER_ERROR = "FETCH_EVENT_BANNER_ERROR",
  UPDATE_EVENT_BANNER_START = "UPDATE_EVENT_BANNER_START",
  UPDATE_EVENT_BANNER_SUCCESS = "UPDATE_EVENT_BANNER_SUCCESS",
  UPDATE_EVENT_BANNER_ERROR = "UPDATE_EVENT_BANNER_ERROR",
  CREATE_EVENT_BANNER_START = "CREATE_EVENT_BANNER_START",
  CREATE_EVENT_BANNER_SUCCESS = "CREATE_EVENT_BANNER_SUCCESS",
  CREATE_EVENT_BANNER_ERROR = "CREATE_EVENT_BANNER_ERROR",
  DELETE_EVENT_BANNER_START = "DELETE_EVENT_BANNER_START",
  DELETE_EVENT_BANNER_SUCCESS = "DELETE_EVENT_BANNER_SUCCESS",
  DELETE_EVENT_BANNER_ERROR = "DELETE_EVENT_BANNER_ERROR",
  FETCH_EVENT_PROPS_LIST_START = "FETCH_EVENT_PROPS_LIST_START",
  FETCH_EVENT_PROPS_LIST_SUCCESS = "FETCH_EVENT_PROPS_LIST_SUCCESS",
  FETCH_EVENT_PROPS_LIST_ERROR = "FETCH_EVENT_PROPS_LIST_ERROR",
  UPDATE_EVENT_PROP_START = "UPDATE_EVENT_PROP_START",
  UPDATE_EVENT_PROP_SUCCESS = "UPDATE_EVENT_PROP_SUCCESS",
  UPDATE_EVENT_PROP_ERROR = "UPDATE_EVENT_PROP_ERROR",
  FETCH_REGION_EVENT_TOPIC_LIST_START = "FETCH_REGION_EVENT_TOPIC_LIST_START",
  FETCH_REGION_EVENT_TOPIC_LIST_SUCCESS = "FETCH_REGION_EVENT_TOPIC_LIST_SUCCESS",
  FETCH_REGION_EVENT_TOPIC_LIST_ERROR = "FETCH_REGION_EVENT_TOPIC_LIST_ERROR",
  CREATE_REGION_BANNER_START = "CREATE_REGION_BANNER_START",
  CREATE_REGION_BANNER_SUCCESS = "CREATE_REGION_BANNER_SUCCESS",
  CREATE_REGION_BANNER_ERROR = "CREATE_REGION_BANNER_ERROR",
  UPDATE_REGION_BANNER_START = "UPDATE_REGION_BANNER_START",
  UPDATE_REGION_BANNER_SUCCESS = "UPDATE_REGION_BANNER_SUCCESS",
  UPDATE_REGION_BANNER_ERROR = "UPDATE_REGION_BANNER_ERROR",
  DELETE_REGION_BANNER_START = "DELETE_REGION_BANNER_START",
  DELETE_REGION_BANNER_SUCCESS = "DELETE_REGION_BANNER_SUCCESS",
  DELETE_REGION_BANNER_ERROR = "DELETE_REGION_BANNER_ERROR",
  FETCH_SELLER_LIST_START = "FETCH_SELLER_LIST_START",
  FETCH_SELLER_LIST_SUCCESS = "FETCH_SELLER_LIST_SUCCESS",
  FETCH_SELLER_LIST_ERROR = "FETCH_SELLER_LIST_ERROR",
  DL_SHIPPING_DETAIL_START = "DL_SHIPPING_DETAIL_START",
  DL_SHIPPING_DETAIL_SUCCESS = "DL_SHIPPING_DETAIL_SUCCESS",
  DL_SHIPPING_DETAIL_ERROR = "DL_SHIPPING_DETAIL_ERROR",
  FETCH_VENUE_AREA_LIST_START = "FETCH_VENUE_AREA_LIST_START",
  FETCH_VENUE_AREA_LIST_SUCCESS = "FETCH_VENUE_AREA_LIST_SUCCESS",
  FETCH_VENUE_AREA_LIST_ERROR = "FETCH_VENUE_AREA_LIST_ERROR",
  FETCH_VENUES_LIST_START = "FETCH_VENUES_LIST_START",
  FETCH_VENUES_LIST_SUCCESS = "FETCH_VENUES_LIST_SUCCESS",
  FETCH_VENUES_LIST_ERROR = "FETCH_VENUES_LIST_ERROR",
  FETCH_VENUE_DETAIL_START = "FETCH_VENUE_DETAIL_START",
  FETCH_VENUE_DETAIL_SUCCESS = "FETCH_VENUE_DETAIL_SUCCESS",
  FETCH_VENUE_DETAIL_ERROR = "FETCH_VENUE_DETAIL_ERROR",
  CREATE_VENUE_START = "CREATE_VENUE_START",
  CREATE_VENUE_SUCCESS = "CREATE_VENUE_SUCCESS",
  CREATE_VENUE_ERROR = "CREATE_VENUE_ERROR",
  UPDATE_VENUE_START = "UPDATE_VENUE_START",
  UPDATE_VENUE_SUCCESS = "UPDATE_VENUE_SUCCESS",
  UPDATE_VENUE_ERROR = "UPDATE_VENUE_ERROR",
  FETCH_CREW_LIST_START = "FETCH_CREW_LIST_START",
  FETCH_CREW_LIST_SUCCESS = "FETCH_CREW_LIST_SUCCESS",
  FETCH_CREW_LIST_ERROR = "FETCH_CREW_LIST_ERROR",
  FETCH_EMP_LIST_START = "FETCH_EMP_LIST_START",
  FETCH_EMP_LIST_SUCCESS = "FETCH_EMP_LIST_SUCCESS",
  FETCH_EMP_LIST_ERROR = "FETCH_EMP_LIST_ERROR",
  ADD_CREW_START = "ADD_CREW_START",
  ADD_CREW_SUCCESS = "ADD_CREW_SUCCESS",
  ADD_CREW_ERROR = "ADD_CREW_ERROR",
  UPDATE_CREW_START = "UPDATE_CREW_START",
  UPDATE_CREW_SUCCESS = "UPDATE_CREW_SUCCESS",
  UPDATE_CREW_ERROR = "UPDATE_CREW_ERROR",
  FETCH_SELECT_CREW_LIST_START = "FETCH_SELECT_CREW_LIST_START",
  FETCH_SELECT_CREW_LIST_SUCCESS = "FETCH_SELECT_CREW_LIST_SUCCESS",
  FETCH_SELECT_CREW_LIST_ERROR = "FETCH_SELECT_CREW_LIST_ERROR",
  FETCH_POINTS_VALUE_LIST_START = "FETCH_POINTS_VALUE_LIST_START",
  FETCH_POINTS_VALUE_LIST_SUCCESS = "FETCH_POINTS_VALUE_LIST_SUCCESS",
  FETCH_POINTS_VALUE_LIST_ERROR = "FETCH_POINTS_VALUE_LIST_ERROR",
  CREATE_POINTS_VALUE_START = "CREATE_POINTS_VALUE_START",
  CREATE_POINTS_VALUE_SUCCESS = "CREATE_POINTS_VALUE_SUCCESS",
  CREATE_POINTS_VALUE_ERROR = "CREATE_POINTS_VALUE_ERROR",
  UPDATE_POINTS_VALUE_START = "UPDATE_POINTS_VALUE_START",
  UPDATE_POINTS_VALUE_SUCCESS = "UPDATE_POINTS_VALUE_SUCCESS",
  UPDATE_POINTS_VALUE_ERROR = "UPDATE_POINTS_VALUE_ERROR",
  DELETE_POINTS_VALUE_START = "DELETE_POINTS_VALUE_START",
  DELETE_POINTS_VALUE_SUCCESS = "DELETE_POINTS_VALUE_SUCCESS",
  DELETE_POINTS_VALUE_ERROR = "DELETE_POINTS_VALUE_ERROR",
  FETCH_EVENT_MAIN_TYPES_LIST_START = "FETCH_EVENT_MAIN_TYPES_LIST_START",
  FETCH_EVENT_MAIN_TYPES_LIST_SUCCESS = "FETCH_EVENT_MAIN_TYPES_LIST_SUCCESS",
  FETCH_EVENT_MAIN_TYPES_LIST_ERROR = "FETCH_EVENT_MAIN_TYPES_LIST_ERROR",
  FETCH_EVENT_SUB_TYPES_LIST_START = "FETCH_EVENT_SUB_TYPES_LIST_START",
  FETCH_EVENT_SUB_TYPES_LIST_SUCCESS = "FETCH_EVENT_SUB_TYPES_LIST_SUCCESS",
  FETCH_EVENT_SUB_TYPES_LIST_ERROR = "FETCH_EVENT_SUB_TYPES_LIST_ERROR",
  FETCH_EVENT_DESCRIPTION_START = "FETCH_EVENT_DESCRIPTION_START",
  FETCH_EVENT_DESCRIPTION_SUCCESS = "FETCH_EVENT_DESCRIPTION_SUCCESS",
  FETCH_EVENT_DESCRIPTION_ERROR = "FETCH_EVENT_DESCRIPTION_ERROR",
  FETCH_EVENT_NOTICE_START = "FETCH_EVENT_NOTICE_START",
  FETCH_EVENT_NOTICE_SUCCESS = "FETCH_EVENT_NOTICE_SUCCESS",
  FETCH_EVENT_NOTICE_ERROR = "FETCH_EVENT_NOTICE_ERROR",
  UPDATE_EVENT_DESCRIPTION_START = "UPDATE_EVENT_DESCRIPTION_START",
  UPDATE_EVENT_DESCRIPTION_SUCCESS = "UPDATE_EVENT_DESCRIPTION_SUCCESS",
  UPDATE_EVENT_DESCRIPTION_ERROR = "UPDATE_EVENT_DESCRIPTION_ERROR",
  CREATE_EVENT_NOTICE_START = "CREATE_EVENT_NOTICE_START",
  CREATE_EVENT_NOTICE_SUCCESS = "CREATE_EVENT_NOTICE_SUCCESS",
  CREATE_EVENT_NOTICE_ERROR = "CREATE_EVENT_NOTICE_ERROR",
  DELETE_EVENT_NOTICE_START = "DELETE_EVENT_NOTICE_START",
  DELETE_EVENT_NOTICE_SUCCESS = "DELETE_EVENT_NOTICE_SUCCESS",
  DELETE_EVENT_NOTICE_ERROR = "DELETE_EVENT_NOTICE_ERROR",
  UPDATE_EVENT_NOTICE_START = "UPDATE_EVENT_NOTICE_START",
  UPDATE_EVENT_NOTICE_SUCCESS = "UPDATE_EVENT_NOTICE_SUCCESS",
  UPDATE_EVENT_NOTICE_ERROR = "UPDATE_EVENT_NOTICE_ERROR",
  SORT_EVENT_NOTICE_START = "SORT_EVENT_NOTICE_START",
  SORT_EVENT_NOTICE_SUCCESS = "SORT_EVENT_NOTICE_SUCCESS",
  SORT_EVENT_NOTICE_ERROR = "SORT_EVENT_NOTICE_ERROR",
  FETCH_POINTS_VALUE_SUMMARY_START = "FETCH_POINTS_VALUE_SUMMARY_START",
  FETCH_POINTS_VALUE_SUMMARY_SUCCESS = "FETCH_POINTS_VALUE_SUMMARY_SUCCESS",
  FETCH_POINTS_VALUE_SUMMARY_ERROR = "FETCH_POINTS_VALUE_SUMMARY_ERROR",
  FETCH_POINTS_LIST_START = "FETCH_POINTS_LIST_START",
  FETCH_POINTS_LIST_SUCCESS = "FETCH_POINTS_LIST_SUCCESS",
  FETCH_POINTS_LIST_ERROR = "FETCH_POINTS_LIST_ERROR",
  FETCH_AREA_LIST_START = "FETCH_AREA_LIST_START",
  FETCH_AREA_LIST_SUCCESS = "FETCH_AREA_LIST_SUCCESS",
  FETCH_AREA_LIST_ERROR = "FETCH_AREA_LIST_ERROR",
  FETCH_CONTENT_LIST_START = "FETCH_CONTENT_LIST_START",
  FETCH_CONTENT_LIST_SUCCESS = "FETCH_CONTENT_LIST_SUCCESS",
  FETCH_CONTENT_LIST_ERROR = "FETCH_CONTENT_LIST_ERROR",
  FETCH_TYPE_LIST_START = "FETCH_TYPE_LIST_START",
  FETCH_TYPE_LIST_SUCCESS = "FETCH_TYPE_LIST_SUCCESS",
  FETCH_TYPE_LIST_ERROR = "FETCH_TYPE_LIST_ERROR",
  FETCH_DATA_LIST_START = "FETCH_DATA_LIST_START",
  FETCH_DATA_LIST_SUCCESS = "FETCH_DATA_LIST_SUCCESS",
  FETCH_DATA_LIST_ERROR = "FETCH_DATA_LIST_ERROR",
  CREATE_POINT_START = "CREATE_POINT_START",
  CREATE_POINT_SUCCESS = "CREATE_POINT_SUCCESS",
  CREATE_POINT_ERROR = "CREATE_POINT_ERROR",
  DELETE_POINT_START = "DELETE_POINT_START",
  DELETE_POINT_SUCCESS = "DELETE_POINT_SUCCESS",
  DELETE_POINT_ERROR = "DELETE_POINT_ERROR",
  UPDATE_POINT_START = "UPDATE_POINT_START",
  UPDATE_POINT_SUCCESS = "UPDATE_POINT_SUCCESS",
  UPDATE_POINT_ERROR = "UPDATE_POINT_ERROR",
  FETCH_APP_HOME_IMAGE_LIST_START = "FETCH_APP_HOME_IMAGE_LIST_START",
  FETCH_APP_HOME_IMAGE_LIST_SUCCESS = "FETCH_APP_HOME_IMAGE_LIST_SUCCESS",
  FETCH_APP_HOME_IMAGE_LIST_ERROR = "FETCH_APP_HOME_IMAGE_LIST_ERROR",
  UPDATE_APP_HOME_IMAGE_START = "UPDATE_APP_HOME_IMAGE_START",
  UPDATE_APP_HOME_IMAGE_SUCCESS = "UPDATE_APP_HOME_IMAGE_SUCCESS",
  UPDATE_APP_HOME_IMAGE_ERROR = "UPDATE_APP_HOME_IMAGE_ERROR",
  FETCH_CROSS_MARKET_USER_LIST_START = "FETCH_CROSS_MARKET_USER_LIST_START",
  FETCH_CROSS_MARKET_USER_LIST_SUCCESS = "FETCH_CROSS_MARKET_USER_LIST_SUCCESS",
  FETCH_CROSS_MARKET_USER_LIST_ERROR = "FETCH_CROSS_MARKET_USER_LIST_ERROR",
  CREATE_CROSS_MARKET_USER_START = "CREATE_CROSS_MARKET_USER_START",
  CREATE_CROSS_MARKET_USER_SUCCESS = "CREATE_CROSS_MARKET_USER_SUCCESS",
  CREATE_CROSS_MARKET_USER_ERROR = "CREATE_CROSS_MARKET_USER_ERROR",
  UPDATE_CROSS_MARKET_USER_START = "UPDATE_CROSS_MARKET_USER_START",
  UPDATE_CROSS_MARKET_USER_SUCCESS = "UPDATE_CROSS_MARKET_USER_SUCCESS",
  UPDATE_CROSS_MARKET_USER_ERROR = "UPDATE_CROSS_MARKET_USER_ERROR",
  DELETE_CROSS_MARKET_USER_START = "DELETE_CROSS_MARKET_USER_START",
  DELETE_CROSS_MARKET_USER_SUCCESS = "DELETE_CROSS_MARKET_USER_SUCCESS",
  DELETE_CROSS_MARKET_USER_ERROR = "DELETE_CROSS_MARKET_USER_ERROR",
  FETCH_IMPORTANT_NOTICE_START = "FETCH_IMPORTANT_NOTICE_START",
  FETCH_IMPORTANT_NOTICE_SUCCESS = "FETCH_IMPORTANT_NOTICE_SUCCESS",
  FETCH_IMPORTANT_NOTICE_ERROR = "FETCH_IMPORTANT_NOTICE_ERROR",
  UPDATE_IMPORTANT_NOTICE_START = "UPDATE_IMPORTANT_NOTICE_START",
  UPDATE_IMPORTANT_NOTICE_SUCCESS = "UPDATE_IMPORTANT_NOTICE_SUCCESS",
  UPDATE_IMPORTANT_NOTICE_ERROR = "UPDATE_IMPORTANT_NOTICE_ERROR",
  UPDATE_EXTRA_FEE_START = "UPDATE_EXTRA_FEE_START",
  UPDATE_EXTRA_FEE_SUCCESS = "UPDATE_EXTRA_FEE_SUCCESS",
  UPDATE_EXTRA_FEE_ERROR = "UPDATE_EXTRA_FEE_ERROR",
  UPDATE_TO_PAYMENT_START = "UPDATE_TO_PAYMENT_START",
  UPDATE_TO_PAYMENT_SUCCESS = "UPDATE_TO_PAYMENT_SUCCESS",
  UPDATE_TO_PAYMENT_ERROR = "UPDATE_TO_PAYMENT_ERROR",
  FETCH_MAIL_ORDER_SUBTOTAL_START = "FETCH_MAIL_ORDER_SUBTOTAL_START",
  FETCH_MAIL_ORDER_SUBTOTAL_SUCCESS = "FETCH_MAIL_ORDER_SUBTOTAL_SUCCESS",
  FETCH_MAIL_ORDER_SUBTOTAL_ERROR = "FETCH_MAIL_ORDER_SUBTOTAL_ERROR",
  FETCH_PREVIEW_DOC_LIST_START = "FETCH_PREVIEW_DOC_LIST_START",
  FETCH_PREVIEW_DOC_LIST_SUCCESS = "FETCH_PREVIEW_DOC_LIST_SUCCESS",
  FETCH_PREVIEW_DOC_LIST_ERROR = "FETCH_PREVIEW_DOC_LIST_ERROR",
  CREATE_EVENT_TYPE_DESCRIPTION_START = "CREATE_EVENT_TYPE_DESCRIPTION_START",
  CREATE_EVENT_TYPE_DESCRIPTION_SUCCESS = "CREATE_EVENT_TYPE_DESCRIPTION_SUCCESS",
  CREATE_EVENT_TYPE_DESCRIPTION_ERROR = "CREATE_EVENT_TYPE_DESCRIPTION_ERROR",
  FETCH_LOCK_STATUS_START = "FETCH_LOCK_STATUS_START",
  FETCH_LOCK_STATUS_SUCCESS = "FETCH_LOCK_STATUS_SUCCESS",
  FETCH_LOCK_STATUS_ERROR = "FETCH_LOCK_STATUS_ERROR",
  UPDATE_LOCK_STATUS_START = "UPDATE_LOCK_STATUS_START",
  UPDATE_LOCK_STATUS_SUCCESS = "UPDATE_LOCK_STATUS_SUCCESS",
  UPDATE_LOCK_STATUS_ERROR = "UPDATE_LOCK_STATUS_ERROR",
  UPDATE_PAY_DATE_START = "UPDATE_PAY_DATE_START",
  UPDATE_PAY_DATE_SUCCESS = "UPDATE_PAY_DATE_SUCCESS",
  UPDATE_PAY_DATE_ERROR = "UPDATE_PAY_DATE_ERROR",
  FETCH_CAP_COUNT_START = "FETCH_CAP_COUNT_START",
  FETCH_CAP_COUNT_SUCCESS = "FETCH_CAP_COUNT_SUCCESS",
  FETCH_CAP_COUNT_ERROR = "FETCH_CAP_COUNT_ERROR",
  DELETE_EXTRA_FEES_START = "DELETE_EXTRA_FEES_START",
  DELETE_EXTRA_FEES_SUCCESS = "DELETE_EXTRA_FEES_SUCCESS",
  DELETE_EXTRA_FEES_ERROR = "DELETE_EXTRA_FEES_ERROR",
  FETCH_EVENT_TYPE_START = "FETCH_EVENT_TYPE_START",
  FETCH_EVENT_TYPE_SUCCESS = "FETCH_EVENT_TYPE_SUCCESS",
  FETCH_EVENT_TYPE_ERROR = "FETCH_EVENT_TYPE_ERROR",
  FETCH_EVENT_REPORT_AREAS_START = "FETCH_EVENT_REPORT_AREAS_START",
  FETCH_EVENT_REPORT_AREAS_SUCCESS = "FETCH_EVENT_REPORT_AREAS_SUCCESS",
  FETCH_EVENT_REPORT_AREAS_ERROR = "FETCH_EVENT_REPORT_AREAS_ERROR",
  DL_EVENT_REPORT_START = "DL_EVENT_REPORT_START",
  DL_EVENT_REPORT_SUCCESS = "DL_EVENT_REPORT_SUCCESS",
  DL_EVENT_REPORT_ERROR = "DL_EVENT_REPORT_ERROR",
}
